import React, { useEffect } from "react";
import { gameCore } from "./game/gameCore";

const Game = () => {
  useEffect(() => {
    gameCore("T");
  });

  return (
    <>
      <div id={"T"}></div>
    </>
  );
};

export default Game;
