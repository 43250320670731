import * as React from "react";
import logo from "../../../../assets/ozkey_web.png";

export const SolKeyData = [
  {
    name: "happy",
    image: logo,
    content: (
      <>
        <h1>h1</h1>
      </>
    ),
  },
  {
    name: "sad",
    image: logo,
    content: (
      <>
        <h1>h2</h1>
      </>
    ),
  },
];
