import * as React from "react";

import BlogEntry from "../../../componenets/ui/blog/BlogEntry";

import OzkeySplash from "../../../assets/ozkeySplash.webp";
import Typography from "@mui/material/Typography";

function Blog20210919() {
  return (
    <BlogEntry
      icon={OzkeySplash}
      title={"New logo"}
      date={new Date(2021, 9, 19)}
    >
      <Typography variant="body2" gutterBottom>
        Updated Website with new banner and new everything :-p
      </Typography>
    </BlogEntry>
  );
}

export default Blog20210919;
