import * as React from "react";

import Typography from "@mui/material/Typography";
import OzkeyHeader from "../OzkeyHeader/OzkeyHeader";
import { Paper } from "@mui/material";
import OzkeyFooter from "../OzkeyFooter/Footer";

import "./OzkeyPage.css";

function Projects({ children }) {
  return (
    <>
      <OzkeyHeader />
      <Paper sx={{ p: 2 }} className={"OzkeyPage"}>
        {children}
        <Typography variant="body2" gutterBottom>
          © {new Date().getFullYear()} Oscar Aguilera. All rights reserved.
        </Typography>
      </Paper>
      <OzkeyFooter />
    </>
  );
}

export default Projects;
