import * as React from "react";

import BlogEntry from "../../../componenets/ui/blog/BlogEntry";

import OzkeySplash from "../../../assets/ozkey_web.png";
import Typography from "@mui/material/Typography";

function Blog20210918() {
  return (
    <BlogEntry
      icon={OzkeySplash}
      title={"New Website"}
      date={new Date(2021, 9, 18)}
    >
      <Typography variant="body2" gutterBottom>
        Created a new logo for Ozkey and updated all social media. this took a
        lot longer than I thought it was going to be.
      </Typography>
    </BlogEntry>
  );
}

export default Blog20210918;
