import * as React from "react";

import { PlanetsData } from "./content/PlanetsData";

import GenericProject from "../../../componenets/GenericProject/GenericProject";
import { useParams } from "react-router-dom";

function Planets() {
  let { project } = useParams();

  return <GenericProject projectData={{ project, cardData: PlanetsData }} />;
}

export default Planets;
