import * as React from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import logo from "../../../assets/ozkey_web.png";
import SocialMedia from "../SocialMedia/SocialMedia";
import { Button, ButtonGroup } from "@mui/material";

export default function OzkeyHeader() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color={"transparent"}>
        <Toolbar>
          {/*<IconButton*/}
          {/*  size="large"*/}
          {/*  edge="start"*/}
          {/*  color="inherit"*/}
          {/*  aria-label="open drawer"*/}
          {/*  sx={{ mr: 2 }}*/}
          {/*>*/}
          {/*  <MenuIcon />*/}
          {/*</IconButton>*/}

          <img src={logo} height={"40px"} alt="logo" />
          <Box sx={{ flexGrow: 1 }} />
          <ButtonGroup variant="text" aria-label="text button group">
            <Button href={"/"}>Home</Button>
            <Button href={"/"}>Art</Button>
            <Button href={"/tech"}>Tech</Button>
          </ButtonGroup>
          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <SocialMedia />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
