import * as React from "react";

import OzkeyHeader from "../../componenets/layout/OzkeyHeader/OzkeyHeader";
import OzkeyFooter from "../../componenets/layout/OzkeyFooter/Footer";
import OzkeySplash from "./Splash/LokiSplash";

import "./App.css";
import Welcome from "./welcome/Welcome";
import { Paper } from "@mui/material";
import Blog from "../blog/Blog";
import Typography from "@mui/material/Typography";
import HeroCards from "./HeroCards/HeroCards";

function App() {
  return (
    <div className="App">
      <OzkeyHeader />
      <OzkeySplash />
      <Paper elevation={3} className="App-paper">
        <Welcome />
        <HeroCards />
        <Blog />

        <Typography variant="body2" gutterBottom>
          © {new Date().getFullYear()} Oscar Aguilera. All rights reserved.
        </Typography>
      </Paper>

      <OzkeyFooter />
    </div>
  );
}

export default App;
