import * as React from "react";

import Typography from "@mui/material/Typography";

import { Card, CardContent } from "@mui/material";

function GenericProjectHeader({ projectData }) {
  return (
    <>
      <Card sx={{ width: "100%", marginBottom: 2 }}>
        <CardContent>
          <Typography
            gutterBottom
            variant="h4"
            component="div"
            color={"secondary"}
          >
            {projectData.project.toUpperCase()}
          </Typography>
          <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
            <p>TODO</p>
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}

export default GenericProjectHeader;
