import * as React from "react";

import Typography from "@mui/material/Typography";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Stack,
} from "@mui/material";

function GenericProjectDetailCard(data) {
  return (
    <Card sx={{ width: "100%", marginBottom: 2 }}>
      <CardMedia component="img" image={data.image} alt={data.name} />
      {/*<img src={data.image} height={"40px"} alt="logo" />*/}

      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {data.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {data.content}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
          <Button variant="contained" color="primary" href={data.buyUrl}>
            Buy now
          </Button>
        </Stack>
      </CardActions>
    </Card>
  );
}

export default GenericProjectDetailCard;
