import * as React from "react";

import { useParams } from "react-router-dom";

import OzkeyPage from "../../componenets/layout/OzkeyPage/OzkeyPage";

import SolKeys from "./solkey/SolKeys";
import Planets from "./planet/Planets";

function Projects() {
  let { project } = useParams();

  return (
    <OzkeyPage>
      {project === "keys" && <SolKeys />}
      {project === "planets" && <Planets />}
    </OzkeyPage>
  );
}

export default Projects;
