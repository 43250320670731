import { BottomNavigation, Paper } from "@mui/material";
import * as React from "react";
import SocialMedia from "../SocialMedia/SocialMedia";

function OzkeyFooter() {
  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation showLabels value={1} onChange={() => {}}>
        <SocialMedia />
      </BottomNavigation>
    </Paper>
  );
}

export default OzkeyFooter;
