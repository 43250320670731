import * as React from "react";
import "./LokiSplah.css";
import splash from "../../../assets/ozkeySplash.webp";

function OzkeySplash() {
  return (
    <div style={{ backgroundImage: `url(${splash})` }} className="OzkeySplash">
      {" "}
    </div>
  );
}

export default OzkeySplash;
