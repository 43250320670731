import * as React from "react";

import Typography from "@mui/material/Typography";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
} from "@mui/material";

export const getGenericProjectCards = (projectData) => {
  const listItems = projectData.cardData.map((data) => (
    <>
      <Grid item xs={12} sm={12} md={6} lg={3}>
        <Card>
          <CardMedia
            component="img"
            height="300"
            image={data.image}
            alt="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {data.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {data.content}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" href={`/${projectData.project}/${data.name}`}>
              More Info
            </Button>
            <Button size="small">Buy</Button>
          </CardActions>
        </Card>
      </Grid>
    </>
  ));
  return listItems;
};
