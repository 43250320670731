import * as React from "react";

import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Blog20210919 from "./content/20210919";
import Blog20210918 from "./content/20210918";

function Blog() {
  return (
    <>
      <Typography gutterBottom variant="h4" component="div" color={"secondary"}>
        Blog
      </Typography>

      <Grid container spacing={{ xs: 2 }} sx={{ marginBottom: 2 }}>
        <Blog20210919 />
        <Blog20210918 />
      </Grid>
    </>
  );
}

export default Blog;
