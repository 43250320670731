import * as React from "react";

import OzkeyPage from "../../componenets/layout/OzkeyPage/OzkeyPage";
import Snake from "./game/Snake"


function SnakePage() {
  return (
    <OzkeyPage>
      <Snake/>
    </OzkeyPage>
  );
}

export default SnakePage;
