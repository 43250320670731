import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";

const Direction = {
  Up: "UP",
  Down: "DOWN",
  Left: "LEFT",
  Right: "RIGHT",
};
let snakeDirection = Direction.Down;
let timePassed = 0;

const setSnakeDirection = (event) => {
  switch (event.key) {
    case "ArrowLeft":
      snakeDirection = Direction.Left;
      break;
    case "ArrowRight":
      snakeDirection = Direction.Right;
      break;
    case "ArrowUp":
      snakeDirection = Direction.Up;
      break;
    case "ArrowDown":
      snakeDirection = Direction.Down;
      break;
    default:
  }
};

const getNewHead = (snakeParts) => {
  const head = snakeParts[snakeParts.length - 1];
  switch (snakeDirection) {
    case Direction.Left:
      return { x: head.x - 1, y: head.y };

    case Direction.Right:
      return { x: head.x + 1, y: head.y };

    case Direction.Up:
      return { x: head.x, y: head.y - 1 };

    case Direction.Down:
      return { x: head.x, y: head.y + 1 };
    default:
  }
};

const Snake = () => {
  // Declare a new state variable, which we'll call "count"
  // x >   y ^
  const [snakeParts, setSnakeParts] = useState([
    { x: 10, y: 10 },
    { x: 10, y: 11 },
    { x: 10, y: 12 },
  ]);

  const [food, setfood] = useState([{ x: 5, y: 1 }]);

  const [endGame, setEndGame] = useState(false);

  let stop = false;

  const main = (time) => {
    if (stop || endGame) return;
    if (timePassed / 100 + 2 < time / 100) {
      timePassed = time;

      const newSnakeParts = [...snakeParts];
      const newHead = getNewHead(snakeParts);
      if (newHead.x > 20 || newHead.y > 20 || newHead.x < 1 || newHead.y < 1)
        setEndGame(true);

      console.log(timePassed, snakeDirection, newHead, food[0], stop);

      if (food.find((p) => p.x === newHead.x && p.y === newHead.y)) {
        const foodEaten = food.filter(
          (p) => !(p.x === newHead.x && p.y === newHead.y)
        );

        foodEaten.push({
          x: Math.floor(Math.random() * 20) + 1,
          y: Math.floor(Math.random() * 20) + 1,
        });
        setfood(foodEaten);
      } else {
        newSnakeParts.shift();
      }

      if (snakeParts.find((p) => p.x === newHead.x && p.y === newHead.y)) {
        setEndGame(true);
        window.removeEventListener("keydown", setSnakeDirection);
        setSnakeParts([newHead]);
      } else {
        newSnakeParts.push(newHead);
        setSnakeParts(newSnakeParts);
      }
    }

    window.requestAnimationFrame(main);
  };

  useEffect(() => {
    window.requestAnimationFrame(main);

    window.addEventListener("keydown", setSnakeDirection);

    return () => {
      // eslint-disable-next-line
      stop = true;
      window.removeEventListener("keydown", setSnakeDirection);
    };
  });

  return (
    <>
      <div>
        {endGame && (
          <>
            <h1>Game over</h1>
            <Button href={"/Snake"}>New game</Button>
            <br />
          </>
        )}
      </div>
      <div
        style={{
          width: "100%",
          padding: 10,
          background: "grey",
          position: "relative",
          display: "block",
        }}
      >
        <div
          style={{
            width: 500,
            height: 500,
            background: "white",
            position: "relative",
            display: "grid",
            margin: "auto",
            gridTemplateColumns: "repeat(20,1fr)",
            gridTemplateRows: "repeat(20,1fr)",
          }}
        >
          {snakeParts.map((pos) => (
            <div
              style={{
                background: "brown",
                gridRowStart: pos.y,
                gridColumnStart: pos.x,
                border: "1px solid green",
                borderRadius: 5,
              }}
            />
          ))}

          {food.map((pos) => (
            <div
              style={{
                background: "red",
                gridRowStart: pos.y,
                gridColumnStart: pos.x,
                border: "1px solid green",
                borderRadius: 15,
              }}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Snake;
