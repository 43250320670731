import * as React from "react";

import { useParams } from "react-router-dom";

import { Grid } from "@mui/material";
import { getGenericProjectCards } from "./GetGenericProjectCards";
import GenericProjectDetailCard from "./GenericProjectDetailCard";
import GenericProjectHeader from "./GenericProjectHeader";

function GenericProject({ projectData }) {
  let { id } = useParams();

  let content;
  if (id === "all") {
    content = (
      <>
        <GenericProjectHeader projectData={projectData} />
        <Grid
          container
          spacing={{ xs: 2 }}
          columns={{ sm: 12, md: 12 }}
          sx={{ marginBottom: 2 }}
        >
          {getGenericProjectCards(projectData)}
        </Grid>
      </>
    );
  } else {
    const data = projectData.cardData.find((data) => {
      return data.name.toLowerCase() === id.toLowerCase();
    });

    content = GenericProjectDetailCard(data);
  }

  return <>{content}</>;
}

export default GenericProject;
