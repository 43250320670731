import * as React from "react";

import card1Splash from "../../projects/apple/assets/Apples.webp";
import card2Splash from "../../projects/cottage/assets/cottage_01.webp";
import card4Splash from "../../projects/planet/assets/planet.webp";
import card5Splash from "../../projects/spider/assets/spider.webp";
import HeroCard from "../../../componenets/ui/card/HeroCard";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

function HeroCards() {
  return (
    <Grid
      container
      spacing={{ xs: 2 }}
      columns={{ sm: 12, md: 12 }}
      sx={{ marginBottom: 2 }}
    >
      <HeroCard
        splash={card4Splash}
        heading="Soleil"
        text="text"
        buyUrl="https://opensea.io/Ozkey?tab=created"
        infoUrl="/planets/all"
      >
        <Typography variant="body2" color="text.secondary">
          <p>
            Join me on my journey into imaginary space and explore these solar
            systems and planets.
          </p>
          <p>What features will these new worlds have?</p>
        </Typography>
      </HeroCard>

      <HeroCard
        splash={card5Splash}
        heading="Insects"
        text="text"
        buyUrl="https://opensea.io/Ozkey?tab=created"
        infoUrl=""
      >
        <Typography variant="body2" color="text.secondary">
          <p>The world of insects real and imagined.</p>
          <p>
            Explore the strange and sometimes beautiful creatures that would
            inhabit strange far away planets.
          </p>
        </Typography>
      </HeroCard>

      <HeroCard
        splash={card1Splash}
        heading="Flora"
        text="text"
        buyUrl="https://opensea.io/Ozkey?tab=created"
        infoUrl=""
      >
        <Typography variant="body2" color="text.secondary">
          <p>
            This project explores the possible plants that would inhabit these
            planets and characteristics.
          </p>
        </Typography>
      </HeroCard>

      <HeroCard
        splash={card2Splash}
        heading="Buildings"
        text="text"
        buyUrl="https://opensea.io/Ozkey?tab=created"
        infoUrl=""
      >
        <Typography variant="body2" color="text.secondary">
          <p>
            This project explores the possible alien buildings, homes and caves.
            Anything that makes life possible. In strange far away worlds
          </p>
        </Typography>
      </HeroCard>
    </Grid>
  );
}

export default HeroCards;
