import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const dateString = (date) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = date.getDate();

  const monthIndex = date.getMonth();
  const monthName = monthNames[monthIndex];

  const year = date.getFullYear();

  return `${day}-${monthName}-${year}`;
};

export default function BlogEntry({ icon, children, title, date, links }) {
  return (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <Paper sx={{ p: 2, margin: "auto", flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item>
            <Img
              alt="complex"
              src={icon}
              style={{ width: "150px", height: "150px", objectFit: "contain" }}
            />
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  color={"primary"}
                  component="div"
                >
                  {title}
                </Typography>
                {children}
              </Grid>
              <Grid item>
                <Typography variant="body2">{links}</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="body2" color="text.secondary">
                {dateString(date)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
