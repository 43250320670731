import * as React from "react";

import { SolKeyData } from "./content/SolKeyData";

import GenericProject from "../../../componenets/GenericProject/GenericProject";

function SolKeys() {
  return (
    <GenericProject projectData={{ cardData: SolKeyData }}></GenericProject>
  );
}

export default SolKeys;
