import * as React from "react";
import { Card, CardContent } from "@mui/material";
import Typography from "@mui/material/Typography";

import "../App.css";

function Welcome() {
  return (
    <Card sx={{ width: "100%", marginBottom: 2 }}>
      <CardContent>
        <Typography
          gutterBottom
          variant="h4"
          component="div"
          color={"secondary"}
        >
          Projects
        </Typography>
        <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
          <p>
            Technology and digital art are my passion. I finally had the
            opportunity to take some time to start some projects of my own.
          </p>
          <p> I hope you find something you like and enjoy.</p>
        </Typography>
      </CardContent>
    </Card>
  );
}

export default Welcome;
