import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { Grid, Stack } from "@mui/material";

export default function HeroCard({
  splash,
  heading,
  buyUrl,
  infoUrl,
  children,
}) {
  return (
    // xs, sm, md, lg, and xl.
    <Grid item xs={12} sm={12} md={6} lg={3}>
      <Card>
        <CardMedia
          component="img"
          height="300"
          image={splash}
          alt="green iguana"
        />
        <CardContent sx={{ height: 150 }}>
          <Typography gutterBottom variant="h5" component="div">
            {heading}
          </Typography>
          {children}
        </CardContent>

        <CardActions disableSpacing>
          <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
            {infoUrl && (
              <Button variant="contained" color="secondary" href={infoUrl}>
                Info
              </Button>
            )}
            <Button variant="contained" color="primary" href={buyUrl}>
              Buy now
            </Button>
          </Stack>
        </CardActions>
      </Card>
    </Grid>
  );
}
