import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Instagram from "@mui/icons-material/Instagram";
import LinkedIn from "@mui/icons-material/LinkedIn";
import Pinterest from "@mui/icons-material/Pinterest";
import Twitter from "@mui/icons-material/Twitter";
import YouTube from "@mui/icons-material/YouTube";
import Facebook from "@mui/icons-material/Facebook";
import { GitHub } from "@mui/icons-material";

export default function SocialMedia() {
  return (
    <>
      <IconButton
        size="large"
        aria-label="Facebook"
        color="primary"
        href="https://www.facebook.com/profile.php?id=100073002818890"
        target="_blank"
      >
        <Facebook />
      </IconButton>

      <IconButton
        size="large"
        aria-label="Instagram"
        color="primary"
        href="https://www.instagram.com/real_ozkey/"
        target="_blank"
      >
        <Instagram />
      </IconButton>
      <IconButton
        size="large"
        aria-label="Pinterest"
        color="primary"
        href="https://www.pinterest.com/ozkey_com"
        target="_blank"
      >
        <Pinterest />
      </IconButton>
      <IconButton
        size="large"
        aria-label="Twitter"
        color="primary"
        href="https://twitter.com/Ozkey"
        target="_blank"
      >
        <Twitter />
      </IconButton>

      <IconButton
        size="large"
        aria-label="YouTube"
        color="primary"
        href="https://www.youtube.com/channel/UCRV06ptKhGUXB2D073p_uUg"
        target="_blank"
      >
        <YouTube />
      </IconButton>

      <IconButton
        size="large"
        aria-label="YouTube"
        color="primary"
        href="https://github.com/ozkey"
        target="_blank"
      >
        <GitHub />
      </IconButton>
      <IconButton
        size="large"
        aria-label="LinkedIn"
        color="primary"
        href="https://au.linkedin.com/in/osaguilera"
        target="_blank"
      >
        <LinkedIn />
      </IconButton>
    </>
  );
}
