import * as React from "react";
import planet1 from "../assets/renders/planet1.webp";
import planet2 from "../assets/renders/planet2.webp";
import planet3 from "../assets/renders/planet3.webp";

export const PlanetsData = [
  {
    name: "Utsma",
    image: planet1,
    content: (
      <>
        <h1>Utsma</h1>
      </>
    ),
  },
  {
    name: "Verde",
    image: planet2,
    content: (
      <>
        <h1>Verde</h1>
      </>
    ),
  },
  {
    name: "Neba",
    image: planet3,
    content: (
      <>
        <h1>Neba</h1>
      </>
    ),
  },
  {
    name: "Utsma",
    image: planet1,
    content: (
      <>
        <h1>Utsma</h1>
      </>
    ),
  },
  {
    name: "Verde",
    image: planet2,
    content: (
      <>
        <h1>Verde</h1>
      </>
    ),
  },
  {
    name: "Neba",
    image: planet3,
    content: (
      <>
        <h1>Neba</h1>
      </>
    ),
  },
  {
    name: "Utsma",
    image: planet1,
    content: (
      <>
        <h1>Utsma</h1>
      </>
    ),
  },
  {
    name: "Verde",
    image: planet2,
    content: (
      <>
        <h1>Verde</h1>
      </>
    ),
  },
  {
    name: "Neba",
    image: planet3,
    content: (
      <>
        <h1>Neba</h1>
      </>
    ),
  },
];
