import React from "react";
import ReactDOM from "react-dom";

import reportWebVitals from "./reportWebVitals";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import App from "./content/root/App";
import Projects from "./content/projects/Projects";
import Tech from "./content/tech/Tech"
import Game from "./content/tech/game/Game"
import SnakePage from "./content/tech/SnakePage"

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact={true} component={App} />
        <Route path="/blog/:id" component={<div />} />
        <Route path="/:project/:id" component={Projects} />
        <Route path="/tech" component={Tech} />
        <Route path="/3d" component={Game} />
        <Route path="/snake" component={SnakePage} />
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
