import * as React from "react";

import OzkeyPage from "../../componenets/layout/OzkeyPage/OzkeyPage";
import { Button } from "@mui/material"

function Tech() {
  return (
    <OzkeyPage>
      <Button href={"/Snake"}>Snake game</Button>
      <Button href={"/3d"}>3d loader</Button>

    </OzkeyPage>
  );
}

export default Tech;
